import { useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import { dollarFormat } from 'constants/DollarsFormat'
import { Opportunity } from 'services/DataStore'
import { OpportunityValuation } from 'types/api-types'
import { Button } from 'components/ui/Button'
import { useGetOpportunity } from 'hooks/api/useQuery.hooks'
import { EditValuationDialog } from 'components/core/v2/deal/dialogs/EditValuationDialog'
import CoInvestValuationDetails from './CoInvestValuationDetails'
import FundValuationDetails from './FundValuationDetails'

interface Props {
  id: string
}

export function Valuation({ id }: Props) {
  const { data: opportunity } = useGetOpportunity(id)
  const [openValuationForm, setOpenValuationForm] = useState<boolean>(false)

  const valuation : OpportunityValuation = opportunity?.valuation || {} as OpportunityValuation
  const isCoinvest = opportunity?.opportunity_type === Opportunity.TYPE_DEALSHARE

  return (
    <>
      <Card className='h-full'>
        <CardHeader className='flex flex-row items-center p-5 space-y-0'>
          <CardTitle>Valuation</CardTitle>
          {
            isCoinvest &&
            <Button
            type='button'
            variant='ghost'
            size='sm'
            disabled={!opportunity}
            className='ml-auto text-success'
            onClick={() => setOpenValuationForm(true)}
          >
            Edit
          </Button>
          }
        </CardHeader>

        <CardContent className='p-5 pt-0'>
        <div className={isCoinvest ? 'w-full' : 'w-3/4'}>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-2">
            {
              isCoinvest ?
                <CoInvestValuationDetails {...valuation} />
                :
                <FundValuationDetails {...valuation} />
            }

            <div className="min-h-[2rem] flex items-center border-b border-gray-200"><b>MOIC:</b></div>
            <div className="min-h-[2rem] flex items-center border-b border-gray-200 col-span-1 lg:col-span-3"><b>{Number(valuation.current_multiple).toFixed(4)}x</b></div>

            <div className="min-h-[2rem] flex items-center border-gray-200"><b>Current value:</b></div>
            <div className="min-h-[2rem] flex items-center border-gray-200 col-span-1 lg:col-span-3"><b>{dollarFormat(valuation.current_value)}</b></div>
          </div>
        </div>
        </CardContent>
      </Card>
      {opportunity && openValuationForm && (
        <EditValuationDialog
          opportunity={opportunity}
          open={openValuationForm}
          toggleOpen={open => {
            setOpenValuationForm(open)
          }}
        />
      )}
    </>
  )
}
