import type { OpportunityValuation } from 'types/api-types'
import { dateFormat } from 'services/TimeUtils'
import { dollarFormat } from 'constants/DollarsFormat'

const CoInvestValuationDetails: React.FC<OpportunityValuation> = (valuation) => [
  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="fund-investment-label">Fund Investment:</div>,
  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="fund-investment-value">{dollarFormat(valuation.fund_total)}</div>,
  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="funds-wired-label">Funds Wired:</div>,
  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="funds-wired-value">{dateFormat(valuation.funding_date)}</div>,

  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="co-invest-label">Co-Invest:</div>,
  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="co-invest-value">{dollarFormat(valuation.investment_total)}</div>,
  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="initial-valuation-label">Initial Valuation:</div>,
  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="initial-valuation-value">{dollarFormat(valuation.initial_post_valuation)}</div>,

  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="total-invested-label">Total Invested:</div>,
  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="total-invested-value">{dollarFormat(valuation.total_invested)}</div>,
  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="current-valuation-label">Current Valuation:</div>,
  <div className="min-h-[2rem] flex items-center border-b border-gray-200" key="current-valuation-value">{dollarFormat(valuation.current_pre_valuation)}</div>,
]

export default CoInvestValuationDetails
