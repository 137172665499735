import React, { memo } from 'react'
import FeatherIcon from 'feather-icons-react'
import styles from 'assets/scss/header.module.scss'

function HeaderDropdown({ label, children, showArrow = true, disabled = false }) {
  return (
    <div
      className={`${styles.navWrapper} ${
        disabled && styles.navDropdownDisabled
      } smushed-tablet:!px-2`}
    >
      <div className={styles.navItem}>
        {label}
        {showArrow && <FeatherIcon className={styles.navArrow} icon={'chevron-down'} size={22} />}
      </div>
      <div className={styles.dropdown}>
        <div className='px-8 py-4 flex flex-col gap-3'>{children}</div>
      </div>
    </div>
  )
}

export default memo(HeaderDropdown)
