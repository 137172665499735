import { useState } from 'react'
import { Pencil, Trash2 } from 'lucide-react'
import { Button } from 'components/ui/Button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import { dollarFormat } from 'constants/DollarsFormat'
import { useGetOpportunity } from 'hooks/api/useQuery.hooks'
import { EditFundInvestmentDialog } from './dialogs/EditFundInvestmentDialog'
import { DeleteFundInvestmentDialog } from './dialogs/DeleteFundInvestmentDialog'
import { DealLoader } from './layouts/Loader'

interface Props {
  id: string
}

export function FundInvestments({ id }: Props) {
  const { data: opportunity } = useGetOpportunity(id)

  const [selectedFundInvestment, setSelectedFundInvestment] = useState<number | null>(null)
  const [openFundInvestment, setOpenFundInvestment] = useState<boolean>(false)
  const [selectedDeleteFund, setSelectedDeleteFund] = useState<number | null>(null)

  if (!opportunity) return <DealLoader.Card />

  return (
    <>
      <Card className='h-full'>
        <CardHeader className='flex flex-row items-center p-5 space-y-0'>
          <CardTitle>Fund Investment</CardTitle>
          <Button
            type='button'
            variant='ghost'
            size='sm'
            className='ml-auto text-success'
            onClick={() => setOpenFundInvestment(true)}
          >
            Add new
          </Button>
        </CardHeader>

        <CardContent className='p-5 pt-0'>
          <ul className='space-y-4'>
            {opportunity?.funds.map((fund, idx) => {
              const totalInvestmentValue =
                +(fund.reserve_amount ?? 0) + +(fund.investment_value ?? 0)

              return (
                <li key={fund.fund_id}>
                  <hgroup className='flex items-center'>
                    <h4 className='text-lg'>
                      <b>{fund.fund_name}:</b> {dollarFormat(totalInvestmentValue)}
                    </h4>

                    <div className='ml-auto flex items-center gap-1'>
                      <Button
                        type='button'
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                          setSelectedFundInvestment(idx)
                          setOpenFundInvestment(true)
                        }}
                      >
                        <Pencil className='w-4 h-4 text-success' />
                      </Button>
                      <Button
                        type='button'
                        size='sm'
                        variant='ghost'
                        onClick={() => setSelectedDeleteFund(idx)}
                      >
                        <Trash2 className='w-4 h-4 text-archived' />
                      </Button>
                    </div>
                  </hgroup>

                  <div className='space-y-2'>
                    <p className='text-base m-0'>
                      <b>Investment Period:</b> {dollarFormat(fund.investment_value)}
                    </p>
                    <p className='text-base m-0'>
                      <b>Reserves:</b> {dollarFormat(fund.reserve_amount ?? 0)}
                    </p>
                  </div>
                </li>
              )
            })}
          </ul>
        </CardContent>
      </Card>

      {opportunity && openFundInvestment && (
        <EditFundInvestmentDialog
          opportunity={opportunity}
          idx={selectedFundInvestment}
          open={openFundInvestment}
          toggleOpen={open => {
            setOpenFundInvestment(open)
            setSelectedFundInvestment(null)
          }}
        />
      )}
      {opportunity && selectedDeleteFund !== null && (
        <DeleteFundInvestmentDialog
          opportunity={opportunity}
          idx={selectedDeleteFund}
          open={selectedDeleteFund !== null}
          toggleOpen={() => setSelectedDeleteFund(null)}
        />
      )}
    </>
  )
}
