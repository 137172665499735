import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/Dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { useShareOpportunityMutate } from 'hooks/api/useMutation.hooks'
import { LoadingButton } from './ui/LoadingButton'
import { Button } from 'components/ui/Button'
import { Textarea } from 'components/ui/Textarea'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'hooks/userToast.hooks'
import MultiEmail from './core/fields/MultiEmail'
import z from 'zod'

interface ShareDealFormProps {
  deal: { id: string }
  close: () => void
  open: boolean
}

const shareDealSchema = z.object({
  emails: z.array(z.string()).min(1, { message: 'You must enter at least one valid email address.' }),
  message: z.string().optional()
})

type ShareDealSchema = z.infer<typeof shareDealSchema>

export default function ShareDealForm({ deal, close, open = false }: ShareDealFormProps) {
  const { mutate, isLoading } = useShareOpportunityMutate()
  const onCloseActions = () => {
    form.reset()
    close()
  }

  const form = useForm<ShareDealSchema>({
    resolver: zodResolver(shareDealSchema),
    defaultValues: {
      emails: [],
      message: 'Check out this investment opportunity!'
    }
  })

  const handleSubmit: SubmitHandler<ShareDealSchema> = values => {
    mutate(
      {
        id: +deal.id,
        emails: values.emails,
        message: values.message
      },
      {
        onSuccess: async () => {
          toast({
            variant: 'success',
            description: 'Deal shared successfully'
          })

          onCloseActions()
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={onCloseActions}>
      <DialogContent className='rounded-md max-w-5xl'>
        <DialogHeader>
          <DialogTitle className='m-0 text-xl text-2xl montserrat flex items-center space-x-2 sm:space-x-4'>
            Share this opportunity
          </DialogTitle>
        </DialogHeader>

        <p className='text-[1em] montserrat mt-4'>
          We hope that you will find our investments compelling enough to share with a colleague,
          family member, or friend. Please enter the email addresses for the people with whom
          you’d like to share this opportunity.
        </p>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name='emails'
              render={({ field }) => {
                const { onChange, value, ...rest } = field
                return (
                  <FormItem>
                    <FormLabel>Recipients email addresses</FormLabel>
                    <FormControl>
                      <MultiEmail
                        setRecipients={onChange}
                        recipients={value}
                        label=''
                        className=''
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            />
            <FormField
              control={form.control}
              name='message'
              render={({ field }) => {
                const { onChange, value, ...rest } = field
                return (
                  <FormItem className='mt-4'>
                    <FormLabel>Message (Optional)</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder='message (optional)'
                        value={value}
                        onChange={value => onChange(value)}
                        size='xl'
                        rows={4}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            />
            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                onClick={onCloseActions}
                className='flex-1 max-w-[262px]'
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
