import qs from 'query-string'
import type {
  AdminDealsPageResponse,
  AdminInvestorsPageResponse,
  DealsSearchQueryParams,
  InvestorsSearchQueryParams,
  OpportunityByTypeResponse,
  OpportunityResponse,
  UserNotification,
  UserInfo,
  PropertiesResponse,
  InvestorResponse,
  DealByOpportunityTypeParams,
  OpportunityCarriesResponse,
  UsersSearchQueryParams,
  AdminUsersPageResponse,
  Admin,
  AdminUsersResponse,
  TagsResponse
} from 'types/api-types'
import {
  authenticatedFetch,
  currentPlatform,
  getToken,
  handleLoginResponse
} from 'services/authentication'
import configuration from 'services/config'

// This function is from authentication.js
// This function is being used in useQuery.hooks to get user data
export async function getUserWithToken() {
  const token = getToken()
  if (!token) {
    return Promise.reject()
  }

  return fetch(configuration.apiUrl + 'login', {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify({
      platform: currentPlatform() // ??
    })
  }).then(handleLoginResponse)
}

export async function getUserNotifications(): Promise<UserNotification[]> {
  const result = (await authenticatedFetch(`notifications?read=false`, {})) as UserNotification[]
  return result
}

export async function getQualifiedPurchaserStatusSurvey() {
  const response = await fetch(`${configuration.apiUrl}surveys/qualified_purchaser_status`)
  return await response.json()
}

export async function getAccreditedInvestorSurvey() {
  const response = await fetch(`${configuration.apiUrl}surveys/accredited_investor`)
  return await response.json()
}

export interface UserData {
  person_fname?: string
  person_lname?: string
  email?: string
  password: string
  qualified_purchaser: boolean
  qualified_purchaser_status_id?: string
  accredited_investor_id?: string
  linkedin_url?: string
  token?: string
}
export interface RegisterResponse {
  name: string
  username: string
  approved?: boolean
}
export async function registerUser(data: UserData) {
  const response = await fetch(`${configuration.apiUrl}register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify(data)
  })
  const result = await response.json()
  if (response.ok) return result as RegisterResponse
  throw new Error('Email is already taken. Please try logging in.')
}

export async function getMember(memberId?: number) {
  return (await authenticatedFetch(`users/${memberId}`, {})) as UserInfo
}

interface UserSearch {
  searchTerm: string
  id: string
  platform: string
}
export interface UserSearchResponse {
  id: number
  person_id: number
  status: string
  created_at: Date
  updated_at: Date
  username: string
  hubspot_id: string
  created_by_invite: boolean
  platform: string
  linkedin_url: string
  creator_id: number
  global_carry: string
  super_admin: boolean
  fname: string
  lname: string
  person: {
    id: string
    prefix: string | null
    fname: string
    minitial: string | null
    lname: string
    suffix: string | null
    birthdate: string | null
    nationality: string | null
    created_at: Date
    updated_at: Date
  }
  name: string
  is_admin: false
  last_sign_in_at: '2024-03-08T21:40:59.431Z'
}
export async function searchUser({ searchTerm, platform, id }: UserSearch) {
  return (await authenticatedFetch(
    `users?search_term=${searchTerm}&statuses[]=approved&platform=${platform}&user_not=${id}&slim=true`,
    {}
  )) as UserSearchResponse[]
}

export interface Fund {
  id: number
  name: string
  fund_year: number
}
export async function getFundsList() {
  const response = await fetch(`${configuration.apiUrl}funds`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const result = await response.json()
  if (response.ok) return result as { funds: Fund[] }
  throw new Error('Something went wrong')
}

export async function getOpportunitiesByOpportunityTypes(type: string) {
  const response = await fetch(`${configuration.apiUrl}opportunities?opportunity_type[]=${type}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const result = (await response.json()) as OpportunityByTypeResponse[]
  if (response.ok) return result
  throw new Error('Could not get opportunities')
}

export async function getOpportunitiesByOpportunityType(params: DealByOpportunityTypeParams) {
  const stringified = qs.stringify(params)
  const response = await fetch(`${configuration.apiUrl}opportunities?${stringified}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const result = (await response.json()) as OpportunityResponse[]
  if (response.ok) return result
  throw new Error('Could not get opportunities')
}

export async function getOpportunities(searchQuery: DealsSearchQueryParams) {
  const url = qs.stringifyUrl(
    { url: 'opportunities', query: { ...searchQuery } },
    { arrayFormat: 'bracket' }
  )
  const token = getToken()
  if (!token) {
    throw new Error('The user must be authenticated to call this function')
  }
  const res = await fetch(`${configuration.apiUrl}${url}`)
  const result = (await res.json()) as OpportunityResponse[]
  const count = res.headers.get('total')

  return { count, data: result } as AdminDealsPageResponse
}

export async function getInvestors(query: InvestorsSearchQueryParams) {
  const url = qs.stringifyUrl(
    { url: 'investors', query: {...query} },
    { arrayFormat: 'bracket' }
  )
  const token = getToken()
  if (!token) {
    throw new Error('The user must be authenticated to call this function')
  }
  const res = await fetch(`${configuration.apiUrl}${url}`)
  const result = (await res.json()) as InvestorResponse[]
  const count = res.headers.get('total')

  return { count, data: result } as AdminInvestorsPageResponse
}
export interface EntitiesResponse {
  amount_requested: string
  created_at: string
  id: number
  name: string
  organization_id: number
  total_funded: string
  updated_at: string
}
export async function getEntitiesByUser(userId: number | undefined) {
  try {
    return (await authenticatedFetch(`entities?user_id=${userId}`, {})) as EntitiesResponse[]
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface CommitmentsResponse {
  id: number
  opportunity_id: number
  amount_requested: string
  status: string
  created_at: string
  updated_at: string
  hubspot_deal_id: string
  final_amount_invested: string
  final_amount_fee: string
  final_amount_total: string
  client_notified_at: string | null
  distributed_amount: string
  created_by_id: number
  entity_id: number
  fee_years: 0
  carry_share: string
  user_id: number
  platform: string
  name: string
  user: {
    id: number
    name: string
    platform: string
    person: { fname: string; lname: string }
  }
  vintage_investment_amount: string
  collection_investment_amount: number | null
  entity: { id: number; name: string }
  organization: {
    id: number
    name: string
    platform: string
  }
  opportunity: {
    id: number
    name: string
    logo: { url: string }
    background_color: string
    dealshare_stage: { name: string }
  }
}

export async function getCommitmentsByClient(userId: number | undefined) {
  try {
    return (await authenticatedFetch(`commitments?user_id=${userId}`, {})) as CommitmentsResponse[]
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function getOpportunity(deal: string) {
  try {
    return (await authenticatedFetch(`opportunities/${deal}`, {
      method: 'GET'
    })) as OpportunityResponse
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function getCommitmentsByOpportunityId(opportunityId: number | undefined) {
  try {
    return (await authenticatedFetch(`commitments?opportunity_id=${opportunityId}`, {
      method: 'GET'
    })) as CommitmentsResponse[]
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function getCommitmentsByOrganizationAndOpportunityId(opportunityId: number | undefined, organization_id: number | undefined) {
  try {
    return (await authenticatedFetch(`commitments?opportunity_id=${opportunityId}&organization_id=${organization_id}`, {
      method: 'GET'
    })) as CommitmentsResponse[]
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function getProperties() {
  try {
    return (await authenticatedFetch('properties', {
      method: 'GET'
    })) as PropertiesResponse[]
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function getInvestorsList(limit?: number) {
  const url = qs.stringifyUrl({ url: 'investors', query: { limit: limit } })
  try {
    return (await authenticatedFetch(url, {
      method: 'GET'
    })) as PropertiesResponse[]
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function getOpportunityCarries(opportunity_id: number) {
  try {
    return (await authenticatedFetch(`carries?opportunity_id=${opportunity_id}`, {
      method: 'GET'
    })) as OpportunityCarriesResponse
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function getOpportunityCarryTrackingByMoic(opportunity_id: number, moic: number) {
  try {
    return (await authenticatedFetch(`opportunities/${opportunity_id}/carry_tracking?moic=${moic}`, {
      method: 'GET'
    })) as OpportunityResponse['carry_tracking']
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function getOpportunityCarryUsers(search_term: string, opportunity_id: number) {
  return (await authenticatedFetch(
    `users?search_term=${search_term}&statuses[]=approved&slim=true&no_carry_for=${opportunity_id}`,
    {}
  )) as UserSearchResponse[]
}

export async function getUsers(searchQuery: UsersSearchQueryParams) {
  const url = qs.stringifyUrl(
    { url: 'users', query: { ...searchQuery } },
    { arrayFormat: 'bracket' }
  )
  const token = getToken()
  if (!token) {
    throw new Error('The user must be authenticated to call this function')
  }

  const res = await fetch(`${configuration.apiUrl}${url}`, {
    headers: {
      'Authorization': `${token}`,
      'Content-Type': 'application/json'
    }
  })
  const result = (await res.json()) as UserInfo[]
  const count = res.headers.get('total')

  return { count, data: result } as AdminUsersPageResponse
}

export async function getAdminUsers() {
  const url = qs.stringifyUrl(
    { url: 'admin_users', query: {} },
    { arrayFormat: 'bracket' }
  )
  const token = getToken()
  if (!token) {
    throw new Error('The user must be authenticated to call this function')
  }

  const res = await fetch(`${configuration.apiUrl}${url}`, {
    headers: {
      'Authorization': `${token}`,
      'Content-Type': 'application/json'
    }
  })
  const result = (await res.json()) as Admin[]
  const count = res.headers.get('total')

  return { count, data: result } as AdminUsersResponse
}

export async function getTags(search_term: string) {
  return (await authenticatedFetch(
    `tags?name=${search_term}`,
    {}
  )) as TagsResponse[]
}
