import { cn } from 'services/utils'
import styles from 'assets/scss/table.module.scss'
import { dateFormat } from 'services/TimeUtils'
import { dollarFormat } from 'constants/DollarsFormat'
import { multipleFormat } from 'constants/MultipleFormat'
import { quantityComparisonColor } from 'themes/styles'

export const Company = {
  Logo: ({ url, name }) => (
    <img
      className='max-w-[48px] md:min-w-[70px] md:max-w-[70px] h-[30px] w-full md:w-auto object-contain brightness-0'
      onError={({ currentTarget }) => {
        currentTarget.className = 'invisible max-h-[0px] max-w-[0px]'
      }}
      src={`${url ?? ''}`}
      alt={name}
    />
  ),
  Name: ({ name, className, onClick }) => {
    return (
      <div
        onClick={onClick}
        className={cn(
          'text-[#6DB557] underline underline-offset-2 montserrat font-[600] cursor-pointer',
          className
        )}
      >
        {name}
      </div>
    )
  }
}

export const ActiveNotification = ({ className }) => {
  return <div className={cn('bg-champion-green rounded-full w-[8px] h-[8px]', className)} />
}

export const DropdownButton = ({ className }) => {
  return (
    <svg
      width='12'
      height='13'
      viewBox='0 0 12 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M2.25 5.32257L6 9.07257L9.75 5.32257'
        stroke='#00060D'
        strokeWidth='0.9'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export const SmDealshareReportCell = ({ data, onClick, showNotification }) => {
  const statusColor = quantityComparisonColor(
    Math.round(Number(data.current_value)),
    Math.round(Number(data.final_amount_invested) || Number(data.investment_in_deal))
  )

  return (
    <>
      <div className='md:hidden flex items-center justify-between gap-x-2 text-xs md:text-base'>
        {data.notification && showNotification ? (
          <ActiveNotification className='w-[6px] h-[6px]' />
        ) : (
          <div className='w-[6px]' />
        )}
        {data.childRows?.length ? <DropdownButton /> : <div className='w-[12px]' />}

        <Company.Logo url={data.logo} name={data.deal} />

        <div className='flex flex-col flex-1 gap-y-1'>
          <div className='flex'>
            <Company.Name name={data.deal} onClick={onClick} className='flex-1' />
            <div className='ml-3'>{data.stage}</div>
          </div>

          <div className='flex'>
            <div className='flex-1'>{data.sector}</div>
            <div className='ml-3'>{dateFormat(data.date)}</div>
          </div>

          <div className='flex'>
            <div className='flex-1'>
              <span className={parseFloat(data.investment)} style={{ color: statusColor }}>
                {multipleFormat(data.moic)}
              </span>
            </div>
            <div className='ml-3'>{data.current_value}</div>
          </div>
        </div>
      </div>

      <div className='hidden md:block'>
        <div className='flex items-center gap-x-4'>
          <Company.Logo url={data.logo} name={data.deal} />
          <Company.Name name={data.deal} onClick={onClick} className='flex-1' />
        </div>
      </div>
    </>
  )
}

export const SmDealshareCell = ({ data, onClick, showNotification }) => {
  const statusColor = quantityComparisonColor(
    Math.round(Number(data.current_value)),
    Math.round(Number(data.final_amount_invested) || Number(data.investment_in_deal))
  )

  return (
    <>
      <div className='md:hidden flex items-center justify-between text-xs gap-x-2 md:text-base'>
        {data.notification && showNotification ? (
          <ActiveNotification className='w-[6px] h-[6px]' />
        ) : (
          <div className='w-[6px]' />
        )}
        {data.childRows?.length ? <DropdownButton /> : <div className='w-[12px]' />}

        <Company.Logo url={data.logo} name={data.deal} />

        <div className='flex flex-col flex-1 gap-y-1 ml-4'>
          <div className='flex'>
            <Company.Name name={data.deal} onClick={onClick} className='flex-1' />
            <div className='ml-3'>{dateFormat(data.date)}</div>
          </div>

          <div className='flex'>
            <div className='flex-1'>
              {data.sector} - {data.stage}
            </div>
            <div className='ml-3'>
              <span className={parseFloat(data.investment)} style={{ color: statusColor }}>
                {multipleFormat(data.moic)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className='hidden md:block'>
        <div className='flex items-center gap-x-4'>
          <Company.Logo url={data.logo} name={data.deal} />
          <Company.Name name={data.deal} onClick={onClick} className='flex-1' />
        </div>
      </div>
    </>
  )
}
export const SmCarryCell = ({ data, onClick, showNotification }) => {
  const statusColor = quantityComparisonColor(
    Math.round(Number(data.current_value)),
    Math.round(Number(data.final_amount_invested) || Number(data.investment_in_deal))
  )

  return (
    <>
      <div className='md:hidden flex items-center justify-between gap-x-2 text-xs md:text-base'>
        {data.notification && showNotification ? (
          <ActiveNotification className='w-[6px] h-[6px]' />
        ) : (
          <div className='w-[6px]' />
        )}
        {data.childRows?.length ? <DropdownButton /> : <div className='w-[12px]' />}

        <Company.Logo url={data.logo} name={data.deal} />
        <div className='flex flex-col flex-1 gap-y-1'>
          <div className='flex'>
            <Company.Name name={data.deal} onClick={onClick} className='flex-1' />
            <div className='ml-3'>{data.stage}</div>
          </div>

          <div className='flex'>
            <div className='flex-1'>{data.sector}</div>
            <div className='ml-3'>{dateFormat(data.date)}</div>
          </div>

          <div className='flex'>
            <div className='flex-1'>
              <span className={parseFloat(data.investment)} style={{ color: statusColor }}>
                {multipleFormat(data.moic)}
              </span>
            </div>
            <div className='ml-3'>{dollarFormat(data.total)}</div>
          </div>
        </div>
      </div>

      <div className='hidden md:block'>
        <div className='flex items-center gap-x-4'>
          <Company.Logo url={data.logo} name={data.deal} />
          <Company.Name name={data.deal} onClick={onClick} className='flex-1' />
        </div>
      </div>
    </>
  )
}

const columnKeys = {
  expand: (cell, className) => ({
    accessorKey: 'expand',
    header: '',
    className
  }),
  // TODO: what is this and how is it supposed to be handled?
  notification: (cell, className) => ({
    accessorKey: 'notification',
    header: '',
    className: className
  }),
  logo: (cell, className) => ({
    accessorKey: 'logo',
    header: 'Company',
    className
  }),
  deal: (cell, className) => ({
    accessorKey: 'deal',
    header: 'Deal',
    className
  }),
  stage: (cell, className) => ({ accessorKey: 'stage', header: 'Stage', className }),
  date: (cell, className) => ({ accessorKey: 'date', cell, header: 'Date', className }),
  sector: (cell, className) => ({ accessorKey: 'sector', header: 'Sector', className }),
  investment: (cell, className) => ({
    accessorKey: 'investment',
    header: 'Investment',
    cell,
    className
  }),
  moic: (cell, className) => ({ accessorKey: 'moic', cell, header: 'MOIC', className }),
  currentValue: (cell, className) => ({
    accessorKey: 'current_value',
    header: 'Current Value',
    cell,
    className
  }),
  total: (cell, className) => ({ accessorKey: 'total', cell, header: 'Total', className })
}

export const portfolioTableCol = {
  coInvest() {
    return [
      columnKeys.notification(undefined, styles.compactCell),
      columnKeys.expand(undefined, styles.compactCell),
      columnKeys.deal(undefined, styles.smOnlyCol),
      columnKeys.stage(),
      columnKeys.date(cell => dateFormat(cell.row.original.date)),
      columnKeys.sector(undefined, styles.lgCol),
      columnKeys.investment(cell => dollarFormat(cell.row.original.investment), styles.lgCol),
      columnKeys.moic(cell => {
        const statusColor = quantityComparisonColor(
          Math.round(Number(cell.row.original.current_value)),
          Math.round(
            Number(cell.row.original.final_amount_invested) ||
              Number(cell.row.original.investment_in_deal)
          )
        )

        return (
          <span className={parseFloat(cell.row.original.investment)} style={{ color: statusColor }}>
            {multipleFormat(cell.row.original.moic)}
          </span>
        )
      }),
      columnKeys.currentValue(cell => cell.row.original.current_value)
    ]
  },
  carry() {
    return [
      columnKeys.notification(undefined, styles.compactCell),
      columnKeys.expand(undefined, styles.compactCell),
      columnKeys.deal(undefined, styles.smOnlyCol),
      columnKeys.stage(),
      columnKeys.date(cell => dateFormat(cell.row.original.date)),
      columnKeys.sector(undefined, styles.lgCol),
      columnKeys.moic(cell => multipleFormat(cell.row.original.moic)),
      columnKeys.total(cell => dollarFormat(cell.row.original.total))
    ]
  },
  vintage() {
    return [
      columnKeys.notification(undefined, styles.compactCell),
      columnKeys.expand(undefined, styles.compactCell),
      columnKeys.deal(undefined, styles.smOnlyCol),
      columnKeys.stage(),
      columnKeys.date(cell => dateFormat(cell.row.original.date)),
      columnKeys.sector(undefined, styles.lgCol),
      columnKeys.moic(cell => {
        const statusColor = quantityComparisonColor(
          Math.round(Number(cell.row.original.current_value)),
          Math.round(
            Number(cell.row.original.final_amount_invested) ||
              Number(cell.row.original.investment_in_deal)
          )
        )

        return (
          <span className={parseFloat(cell.row.original.investment)} style={{ color: statusColor }}>
            {multipleFormat(cell.row.original.moic)}
          </span>
        )
      })
    ]
  }
}
