import React, { memo, useEffect, useState } from 'react'
import FeatherIcon from 'feather-icons-react'

// @ts-expect-error expected
import styles from '../../../assets/scss/table.module.scss'
import { Button } from 'components/ui/Button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/Popover'

function createFundsList(data: Fund[]) {
  return data
    .sort((a, b) => {
      // sort by the newest id
      return b.id - a.id
    })
    .map(({ name, id }) => ({
      label: name,
      value: id
    }))
}

interface Fund {
  fund_year: number
  id: number
  name: string
}

interface FundFilterProps {
  globalFilter: { fund: number | string }
  setGlobalFilter: (
    globalFilter: (f: { fund: number | string }) => { fund: number | string }
  ) => void
  fundsList: Fund[]
}

const FundFilter: React.FC<FundFilterProps> = ({
  globalFilter,
  setGlobalFilter,
  fundsList = []
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant='companiesOutline' role='combobox' size='sm'>
          Fund <FeatherIcon icon='chevron-down' size='16' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='bg-white z-[60] w-[--radix-popover-trigger-width] w-full max-h-[--radix-popover-content-available-height]'>
        <div className='flex flex-col gap-2 sm:gap-4'>
          <label className={styles.radioContainer}>
            <input
              onChange={e =>
                setGlobalFilter(f => {
                  return {
                    ...f,
                    fund: 'all'
                  }
                })
              }
              defaultChecked={true}
              checked={globalFilter.fund === 'all'}
              type='radio'
              name='fund'
              value='all'
              className={styles.customRadio}
            />
            All
          </label>
          {fundsList &&
            createFundsList(fundsList).map(vintage => (
              <label className={styles.radioContainer} key={vintage.value}>
                <input
                  onChange={e =>
                    setGlobalFilter(f => {
                      return {
                        ...f,
                        fund: parseInt(e.target.value)
                      }
                    })
                  }
                  checked={globalFilter.fund === vintage.value}
                  type='radio'
                  name='fund'
                  value={vintage.value}
                  className={styles.customRadio}
                />
                {vintage.label}
              </label>
            ))}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default memo(FundFilter)
