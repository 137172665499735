import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  userKeyFactory,
  registrationKeyFactory,
  fundKeyFactory,
  opportunityKeyFactory,
  dealKeyFactory,
  commitmentKeyFactory,
  staticKeyFactory,
  investorKeyFactory
} from 'helpers/api/factories/userKey'
import {
  getUserNotifications,
  getAccreditedInvestorSurvey,
  getQualifiedPurchaserStatusSurvey,
  registerUser,
  type RegisterResponse,
  type UserData,
  getMember,
  searchUser,
  UserSearchResponse,
  getFundsList,
  Fund,
  getOpportunitiesByOpportunityTypes,
  getOpportunities,
  getInvestors,
  getEntitiesByUser,
  getCommitmentsByClient,
  getUserWithToken,
  getOpportunity,
  getCommitmentsByOpportunityId,
  getProperties,
  getInvestorsList,
  getOpportunitiesByOpportunityType,
  getCommitmentsByOrganizationAndOpportunityId,
  getOpportunityCarries,
  getOpportunityCarryTrackingByMoic,
  getOpportunityCarryUsers,
  getUsers,
  getAdminUsers,
  getTags
} from 'helpers/api/get-api'
import type {
  UserNotification,
  QualifiedPurchaserStatusSurvey,
  AccreditedInvestorSurvey,
  DealsSearchQueryParams,
  UserInfo,
  DealByOpportunityTypeParams,
  UsersSearchQueryParams,
  TagsResponse
} from 'types/api-types'

const minute = 60 * 1000

export function useUserQuery(enabled = true) {
  return useQuery<UserInfo>({
    enabled,
    queryKey: userKeyFactory.user(),
    queryFn: getUserWithToken,
    staleTime: 60 * minute,
    cacheTime: 0
  })
}

export function useMemberQuery(memberId?: number) {
  return useQuery<UserInfo, Error>({
    enabled: !!memberId,
    queryKey: userKeyFactory.member(memberId),
    queryFn: () => getMember(memberId),
    staleTime: 0
  })
}

export function invalidateUserQuery() {
  const queryClient = useQueryClient()
  queryClient.invalidateQueries({ queryKey: userKeyFactory.user() })
}

export function useUserNotificationQuery() {
  return useQuery<UserNotification[]>({
    queryKey: userKeyFactory.notification(),
    queryFn: getUserNotifications,
    staleTime: 5 * minute,
    cacheTime: 0,
    select: data => {
      let current: UserNotification
      const notificationsValues: UserNotification[] = []
      data.forEach(item => {
        if (!current || item.subject_id !== current.subject_id) {
          notificationsValues.push(item)
        }
        current = item
      })
      return notificationsValues
    }
  })
}

export function useQualificationSurveryQuery() {
  return useQuery<QualifiedPurchaserStatusSurvey>({
    queryKey: registrationKeyFactory.qualificationSurvey(),
    queryFn: getQualifiedPurchaserStatusSurvey,
    staleTime: 60 * minute,
    cacheTime: 0,
    retry: 0
  })
}

export function useAccreditedInvestorSurveyQuery() {
  return useQuery<AccreditedInvestorSurvey>({
    queryKey: registrationKeyFactory.accreditedSurvey(),
    queryFn: getAccreditedInvestorSurvey,
    staleTime: 60 * minute,
    cacheTime: 0,
    retry: 0
  })
}

export function useRegisterUserQuery(data: UserData) {
  return useQuery<RegisterResponse, Error>({
    enabled: !!data,
    queryKey: registrationKeyFactory.registerUser(),
    queryFn: () => registerUser(data),
    staleTime: 0,
    cacheTime: 0,
    retry: 0
  })
}

interface SearchUser {
  searchTerm: string
  id: string
  platform: string
}

export function useSearchUserQuery(user: SearchUser, debounceTime = 300) {
  return useQuery<UserSearchResponse[], Error, SearchedUserResponse[]>({
    enabled: !!user.searchTerm,
    queryKey: userKeyFactory.searchMember(user.id, user.searchTerm),
    queryFn: () => searchUser(user),
    keepPreviousData: true,
    staleTime: debounceTime,
    select: data => {
      return data.map(user => ({
        email: user.username,
        name: user.name,
        id: user.id,
        label: user.username,
        value: user.id
      }))
    }
  })
}

export function useSearchUserForOpportunityCarryQuery(search_term: string, opportunity_id: number, debounceTime = 500) {
  return useQuery<UserSearchResponse[], Error, SearchedUserResponse[]>({
    enabled: !!search_term,
    queryKey: userKeyFactory.searchUserForOpportunityCarryQuery(opportunity_id, search_term),
    queryFn: () => getOpportunityCarryUsers(search_term, opportunity_id),
    keepPreviousData: true,
    staleTime: debounceTime,
    select: data => {
      return data.map(user => ({
        email: user.username,
        name: user.name,
        id: user.id,
        label: user.username,
        value: user.id
      }))
    }
  })
}

export interface SearchedUserResponse {
  email: string
  name: string
  id: number
  label: string
  value: number
}

export function useGetFundsListQuery(enabled = true) {
  return useQuery<{ funds: Fund[] }, Error, Fund[]>({
    enabled,
    queryKey: fundKeyFactory.funds(),
    queryFn: getFundsList,
    staleTime: 60 * minute,
    cacheTime: 0,
    retry: 0,
    select: data => data.funds
  })
}

export function useGetOpportunitiesByOpportunityTypes(type: string) {
  return useQuery({
    enabled: !!type,
    queryKey: opportunityKeyFactory.opportunityByType(type),
    queryFn: () => getOpportunitiesByOpportunityTypes(type),
    staleTime: 60 * minute
  })
}

export function useGetClientCommitments(userId: number | undefined) {
  return useQuery({
    enabled: !!userId,
    queryKey: userKeyFactory.commitments(userId),
    queryFn: () => getCommitmentsByClient(userId),
    staleTime: 30 * minute,
    cacheTime: 0,
    retry: 0
  })
}

export function useGetOpportunities(enabled = true, searchQuery: DealsSearchQueryParams) {
  return useQuery({
    enabled,
    queryKey: dealKeyFactory.deals(searchQuery),
    queryFn: () => getOpportunities(searchQuery),
    staleTime: 0
  })
}

export function useGetOpporrunitiesByOpportunityType(params: DealByOpportunityTypeParams) {
  return useQuery({
    enabled: !!params,
    queryKey: dealKeyFactory.opportunityType(params),
    queryFn: () => getOpportunitiesByOpportunityType(params),
    staleTime: 0
  })
}

export function useGetEntitesByUserQuery(userId: number | undefined) {
  return useQuery({
    enabled: !!userId,
    queryKey: userKeyFactory.entities(userId),
    queryFn: () => getEntitiesByUser(userId),
    staleTime: 30 * minute,
    cacheTime: 0,
    retry: 0
  })
}

export function useGetOpportunity(deal: string) {
  return useQuery({
    enabled: !!deal,
    queryKey: opportunityKeyFactory.opportunityByDeal(deal),
    queryFn: () => getOpportunity(deal),
    staleTime: 30 * minute,
    cacheTime: 0,
    retry: 0
  })
}

export function useGetCommitmentsByOpportunityId(opportunityId: number | undefined) {
  return useQuery({
    enabled: !!opportunityId,
    queryKey: commitmentKeyFactory.commitmentsByOpportunityId(opportunityId),
    queryFn: () => getCommitmentsByOpportunityId(opportunityId),
    staleTime: 30 * minute,
    cacheTime: 0,
    retry: 0
  })
}

export function useGetCommitmentsByOrganizationAndOpportunityId(opportunityId: number | undefined, organization_id: number | undefined) {
  return useQuery({
    enabled: !!opportunityId || !!organization_id,
    queryKey: commitmentKeyFactory.commitmentsByOrganizationAndOpportunityId(opportunityId, organization_id),
    queryFn: () => getCommitmentsByOrganizationAndOpportunityId(opportunityId, organization_id),
    staleTime: 30 * minute,
    cacheTime: 0,
    retry: 0
  })
}

export function useGetProperties() {
  return useQuery({
    enabled: true,
    queryKey: staticKeyFactory.properties(),
    queryFn: getProperties,
    staleTime: 60 * minute,
    cacheTime: 0,
    retry: 0
  })
}

export function useGetInvestorsList(limit?: number) {
  return useQuery({
    enabled: true,
    queryKey: staticKeyFactory.investors(limit),
    queryFn: () => getInvestorsList(limit),
    staleTime: 60 * minute,
    cacheTime: 0,
    retry: 0
  })
}

export function useGetInvestors(params: any, enabled = true) {
  return useQuery({
    enabled,
    queryKey: investorKeyFactory.investors(params),
    queryFn: () => getInvestors(params),
    staleTime: 0
  })
}

export function useGetDealType(deal: string) {
  return useQuery({
    enabled: !!deal,
    queryKey: opportunityKeyFactory.opportunityDealType(deal),
    queryFn: () => getOpportunity(deal),
    staleTime: 30 * minute,
    cacheTime: 0,
    retry: 0
  })
}

export function useGetOpportunityCarries(opportunity_id: number, enabled = true) {
  return useQuery({
    enabled,
    queryKey: opportunityKeyFactory.carries(opportunity_id),
    queryFn: () => getOpportunityCarries(opportunity_id),
    staleTime: 0
  })
}
export function useGetUsers(searchQuery: UsersSearchQueryParams, enabled = true) {
  return useQuery({
    enabled,
    queryKey: userKeyFactory.users(searchQuery),
    queryFn: () => getUsers(searchQuery),
    staleTime: 0
  })
}

export function useGetOpportunityCarryTrackingByMoic(opportunity_id: number, moic: number) {
  return useQuery({
    enabled: !!moic,
    queryKey: opportunityKeyFactory.carryTrackingByMoic(opportunity_id),
    queryFn: () => getOpportunityCarryTrackingByMoic(opportunity_id, moic),
    staleTime: 0
  })
}
export function useGetAdminUsers(enabled = true) {
  return useQuery({
    enabled,
    queryKey: userKeyFactory.admins(),
    queryFn: () => getAdminUsers(),
    staleTime: 0
  })
}

export function useGetTagsQuery(search_term: string, debounceTime = 500) {
  return useQuery({
    enabled: !!search_term,
    queryKey: opportunityKeyFactory.tags(search_term),
    queryFn: () => getTags(search_term),
    staleTime: debounceTime
  })
}
